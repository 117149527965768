<template>
  <div class="competitor-billet"
       :class="{'competitor-outlined': alternativeOutline && item.isCompetitor(), 'non-competitor-outlined': alternativeOutline && item.isNotCompetitor()}">
    <div class="competitor-billet-inner"
         @click="clicked">

      <div class="billet-content">
        <div class="billet-logo">
          <template v-if="item?.logo">
            <app-image
                :width="50"
                :height="50"
                :src="cropImage(item?.logo, '50x50', item?.store)"
            />
          </template>
          <template v-else>
            <div class="empty-logo"></div>
          </template>
          <a class="store-type"
             :href="item?.storeUrl"
             target="_blank">
            <img v-if="item?.store === 'APP_STORE'"
                 class="store-image"
                 src="@/assets/images/stores/app_store.png">
            <img v-else
                 class="store-image"
                 src="@/assets/images/stores/google_play.png">
          </a>
        </div>
        <div class="billet-details">
          <div class="name">{{ item?.title }}</div>
          <div class="category">{{ item?.category }}</div>
        </div>
      </div>
      <div class="billet-bottom">
        <div class="rating">
          <div class="css-icons star-icon"></div>
          {{ item?.rating }}
        </div>
      </div>
    </div>
    <div class="billet-competitors">
      <div class="title">Competitor</div>
      <div class="competitor-actions">

        <template v-if="item.isCompetitor()">
          <div class="custom-tooltip competitors-icon">
            <div class="competitor-action-btn competitor cursor-default active">
              <svg-icon icon="check"/>
            </div>
          </div>
        </template>
        <template v-else>
          <custom-tooltip width="auto"
                          :classes="'competitors-icon'"
                          text-align="center"
                          @click="competitorClickHandler('competitor')">
            <template v-slot:slotTrigger>
              <div class="competitor-action-btn competitor"
                   :class="{active: item.isCompetitor(), animated: animated && clickedType === 'competitor'}">
                <svg-icon icon="check"/>
              </div>
            </template>
            <template v-slot:slotContent>
              <template v-if="currentCompetitorsTab !== 'competitor' && currentCompetitorsTab !== 'non-competitor'">
                Mark as Competitor
              </template>
              <template v-else>
                Mark as Competitor
              </template>
            </template>
          </custom-tooltip>
        </template>

        <template v-if="item.isNotCompetitor()">
          <div class="custom-tooltip competitors-icon">
            <div class="competitor-action-btn non-competitor cursor-default active">
              <svg-icon icon="close"/>
            </div>
          </div>
        </template>
        <template v-else>
          <custom-tooltip width="auto"
                          :classes="'competitors-icon'"
                          text-align="center"
                          @click="competitorClickHandler('non-competitor')">
            <template v-slot:slotTrigger>
              <div class="competitor-action-btn non-competitor"
                   :class="{active: item.isNotCompetitor(), animated: animated && item.isNotCompetitor()}">
                <svg-icon icon="close"/>
              </div>
            </template>
            <template v-slot:slotContent>
              <template v-if="currentCompetitorsTab !== 'competitor' && currentCompetitorsTab !== 'non-competitor'">
                Mark as non-Competitor
              </template>
              <template v-else>
                Mark as non-Competitor
              </template>
            </template>
          </custom-tooltip>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import {cropImage} from "@/utils/utils";
import {CustomCompetitor} from "@/api/objects/CustomCompetitor";
import customCompetitors from "@/api/modules/custom-competitors";
import AppImage from "@/components/UI/ImageLazyLoad/index.vue";

export default {
  name: "CompetitorBillet",
  components: {AppImage},
  emits: ['clicked', 'competitor-clicked'],
  props: {
    item: {
      type: CustomCompetitor,
    },
    currentCompetitorsTab: {
      type: String,
    },
    alternativeOutline: {
      type: Boolean,
    }
  },
  data() {
    return {
      animated: false,
      clickedType: '',
    }
  },
  methods: {
    cropImage,
    clicked(e) {
      if (e?.target?.className !== 'store-image') {
        this.$emit('clicked');
      }
    },
    async competitorClickHandler(type) {
      await customCompetitors.changeState(this.item, type);
      this.$emit('competitor-clicked', this.item);

      this.animated = true;
      setTimeout(() => {
        this.animated = false;
      }, 200);
    },
  },
}
</script>

<style scoped lang="scss" src="./styles.scss"></style>