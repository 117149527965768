<template>
  <div class="new-competitors-block">
    <template v-if="+item?.data.id !== +currentAppId">
      <custom-tooltip
          class="competitor-icon"
          :class="{active: item.competitorType === 'non-competitor' || item.competitorType === 'nonCompetitor'}"
          width="200px"
          :straight="true"
          direction-horizontal="left"
          text-align="center"
          @click="competitorChanged('nonCompetitor', item.data.id, item.competitorType)">
        <template v-slot:slotTrigger>
          <div class="competitor-action-btn non-competitor"
               :class="{active: item?.competitorType === 'nonCompetitor'}">
            <svg-icon v-if="mode === 'icons'" icon="close"/>
            <span v-else>
              Non-Competitor
            </span>
          </div>

        </template>
        <template v-slot:slotContent>
          {{ getTooltip(item.competitorType, 'nonCompetitor') }}
        </template>
      </custom-tooltip>

      <custom-tooltip
          class="competitor-icon"
          :class="{active: item.competitorType === 'indirect'}"
          width="220px"
          :straight="true"
          direction-horizontal="left"
          text-align="center"
          @click="competitorChanged('indirect', item.data.id, item.competitorType)">
        <template v-slot:slotTrigger>
          <div class="competitor-action-btn indirect"
               :class="{active: item?.competitorType === 'indirect'}">
            <svg-icon v-if="mode === 'icons'" icon="dotted-circle"/>
            <span v-else>
              Indirect
            </span>
          </div>

        </template>
        <template v-slot:slotContent>
          {{ getTooltip(item.competitorType, 'indirect') }}
        </template>
      </custom-tooltip>

      <custom-tooltip
          class="competitor-icon"
          :class="{active: item.competitorType === 'competitor'}"
          width="170px"
          :straight="true"
          direction-horizontal="left"
          text-align="center"
          @click="competitorChanged('competitor', item.data.id, item.competitorType)">
        <template v-slot:slotTrigger>
          <div class="competitor-action-btn competitor"
               :class="{active: item?.competitorType === 'competitor'}">
            <svg-icon v-if="mode === 'icons'" icon="check"/>
            <span v-else>
              Competitor
            </span>
          </div>
        </template>
        <template v-slot:slotContent>
          {{ getTooltip(item.competitorType, 'competitor') }}
        </template>
      </custom-tooltip>
    </template>
  </div>
</template>

<script>
import SvgIcon from "@/components/UI/SvgIcon/index.vue";

export default {
  name: 'ChangeCompetitorState',
  emits: [
      'competitor-changed',
  ],
  components: {
    SvgIcon
  },
  props: {
    item: Object,
    currentAppId: Number,
    mode: {
      type: String,
      default: 'icons', // or 'texts'
    },
  },
  methods: {
    competitorChanged(newState, id, oldState) {
      this.$emit('competitor-changed', newState, id, oldState);
    },
    getTooltip(currentState, checkState) {
      if (checkState === 'competitor') {
        return currentState === checkState ? 'Marked as Competitor' : 'Mark as Competitor'
      } else if (checkState === 'indirect') {
        return currentState === checkState ? 'Marked as Indirect Competitor' : 'Mark as Indirect Competitor'
      } else {
        return currentState === checkState ? 'Marked as Non-Competitor' : 'Mark as Non-Competitor'
      }
    },
  }
}
</script>

<style lang="scss">
.new-competitors-block {
  display: flex;
  gap: 8px;

  .competitor-icon {
    cursor: pointer;
  }

  .competitor-icon {
    font-size: 16px;
    color: #d1d8ea;
    transition: color .3s;
    cursor: pointer;
    height: var(--competitor-button-size);
    display: flex;
    align-items: center;

    .svg-icons {
      transition: all .3s;
    }

    .competitor-action-btn {
      min-width: var(--competitor-button-size);
      height: var(--competitor-button-size);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: #e4eaf4;
      transition: all .3s;
      cursor: pointer;
      color: var(--neutral-600);

      &.non-competitor {
        .svg-icons {
          font-size: 12px;
        }
      }

      &.indirect {
        .svg-icons {
          font-size: 28px;
          padding-top: 6px;
          padding-left: 4px;
        }
      }
    }

    span {
      padding: 0 8px;
      color: var(--neutral-700);
      font-weight: 600;
    }

    &:hover, &.active {
      .svg-icons, span {
        color: #ffffff;
      }

      .competitor-action-btn.competitor {
        background-color: rgb(56, 183, 127);
      }

      .competitor-action-btn.non-competitor {
        background-color: #f17a54;
      }

      .competitor-action-btn.indirect {
        background-color: var(--secondary-yellow);
      }
    }
  }
}
</style>
